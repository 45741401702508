<template>
  <section class="service">
    <div class="service__desc">

      <div class="service__info">
        <h3>{{ name }}</h3>
        <div class="service__chips">
          <BaseChip v-for="(chip, index) in chips" :key="index" class="service__chip" :type="chip.type">{{ chip.name }}</BaseChip>
        </div>
        <ul class="service__tasks">
          <li class="service__task" v-for="(task, index) in tasks" :key="index"><span>{{ task }}</span></li>
        </ul>
      </div>

      <div class="service__photos">
        <ComplexCard
          class="service__photo"
          v-for="(photo, index) in photos"
          :key="index"
          :service="photo"
          type="marketing"
        />
      </div>
    </div>

    <div class="service__order">
        <div class="service__prices">
          <div class="service__price-sale">{{ cost + ' ₽' }}</div>
          <div class="service__price-full">{{ 'без комплекса ' + fullCost + ' ₽' }}</div>
        </div>
        <baseButton class="service__btn-order" @click.native="$root.$emit('triggerModal', $event)">Заказать</baseButton>
    </div>

  </section>
</template>

<script>
import ComplexCard from '@/components/ComplexCard.vue'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    chips: {
      type: Array,
      required: true
    },
    tasks: {
      type: Array,
      required: true
    },
    cost: {
      type: Number,
      required: true
    },
    fullCost: {
      type: Number,
      required: true
    },
    photos: {
      type: Array,
      required: true
    },
  },
  methods: {
    getImgStyles(file) {
      return {
        background: `linear-gradient(180deg, rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 0.7) 100%), url(${require(`../../assets/complex/${file}`)}) 50% 50%/cover no-repeat`
      }
    }
  },
  components: { ComplexCard }
}
</script>

<style scoped>
.service {
  margin-bottom: 14.375em;
}
@media (max-width: 865px) {
  .service {
  margin-bottom: 5.3125em;
  }
}
.service__desc {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 710px) {
  .service__desc {
    flex-direction: column;
  }
}

.service__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--black);
}
@media (max-width: 710px) {
  .service__info {
    margin-bottom: var(--xl);
  }
}
.service__info h3 {
  margin-bottom: var(--xl);
}
@media (max-width: 426px) {
  .service__info h3 {
    margin-bottom: 1.1539em;
    font-size: 1.625rem;
  }
}
.service__chips {
  margin-bottom: 2.5em;
}
@media (max-width: 426px) {
  .service__chips {
    margin-bottom: var(--xl);
  }
}
.service__chip{
  background: transparent;
  padding: 0;
  margin-right: var(--xl);
}
.service__tasks {
  list-style: '-' inside;
  text-align: left;
  padding: 0;
  margin: 0;
}
.service__task {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
}
.service__task + .service__task {
  margin-top: var(--md);
}
.service__task span{
  margin-left: var(--md);
}

.service__order {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;

  margin-top: 4.0625em;
}
@media (max-width: 710px) {
  .service__order {
    margin-top: 1.875em;
  }
}
@media (max-width: 500px) {
  .service__order {
    flex-direction: column;
  }
}
.service__prices {
  font-family: Raleway;
  font-style: normal;
  text-align: left;
}
.service__price-sale {
  font-weight: bold;
  font-size: 2rem;
  line-height: 120%;
}
.service__price-full {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 140%;
  color: var(--gray);
}
.service__btn-order {
  align-self: center;
  margin-left: 1.875em;
}
@media (max-width: 500px) {
  .service__btn-order {
  align-self: flex-start;
  margin-left: 0;
  margin-top: var(--md);
  }
}

.service__photos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  margin-right: 2.5em;
  --photos-gap: 2.1875em;
  gap: var(--photos-gap);
}
@media (max-width: 1025px) {
  .service__photos {
    margin-right: 0;
  }
}
@media (max-width: 865px) {
  .service__photos {
    grid-template-columns: repeat(2, 1fr);
    --photos-gap: 1.5625em;
  }
}
@media (max-width: 710px) {
  .service__photos {
    grid-template-columns: repeat(3, 1fr);
    --photos-gap: 2.5em;
  }
}
@media (max-width: 500px) {
  .service__photos {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 426px) {
  .service__photos {
    --photos-gap: 2.5em;
  }
}
@media (max-width: 321px) {
  .service__photos {
    --photos-gap: 1.875em;
  }
}
.service__photo {
  position: relative;
  width: 220px;
  height: 220px;
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
}
@media (max-width: 1366px) {
  .service__photo {
    width: 15.23vw;
    height: 16.15vw;
  }
}
@media (max-width: 1138px) {
  .service__photo {
    width: 13.67vw;
    height: 14.37vw;
  }
}
@media (max-width: 865px) {
  .service__photo {
    width: 16.8vw;
    height: 17.8vw;
  }
}
@media (max-width: 710px) {
  .service__photo {
    width: 100%;
    height: 24vw;
  }
}
@media (max-width: 500px) {
  .service__photo {
    width: 100%;
    height: 38vw;
  }
}
@media (max-width: 426px) {
  .service__photo {
    width: 100%;
    height: 41.7vw;
  }
}
@media (max-width: 321px) {
  .service__photo {
    width: 100%;
    height: 137px;
  }
}

.service__photo h5 {
  vertical-align: bottom;
  color: white;
  overflow-wrap: anywhere;
}

.service__photo:after {
  position: absolute;
  display: none;
  content: '';
  top: 50%;
  right: calc(var(--photos-gap) * -1);
  width: var(--photos-gap);
  height: 1px;
  background: var(--black);
}
.service__photo:before {
  position: absolute;
  display: none;
  content: '';
  bottom: calc(var(--photos-gap) * -1);
  left: 50%;
  width: 1px;
  height: var(--photos-gap);
  background: var(--black);
}

.service__photo:nth-child(1):after,
.service__photo:nth-child(2):after,
.service__photo:nth-child(4):after,
.service__photo:nth-child(1):before {
  display: block;
}
@media (max-width: 865px) {
  .service__photo:nth-child(2):after,
  .service__photo:nth-child(4):after,
  .service__photo:nth-child(1):before {
    display: none;
  }
  .service__photo:nth-child(1):after,
  .service__photo:nth-child(3):after,
  .service__photo:nth-child(3):before,
  .service__photo:nth-child(2):before {
    display: block;
  }
}
@media (max-width: 710px) {
  .service__photo:nth-child(1):after,
  .service__photo:nth-child(2):after,
  .service__photo:nth-child(4):after,
  .service__photo:nth-child(1):before {
    display: block;
  }
  .service__photo:nth-child(3):after,
  .service__photo:nth-child(3):before,
  .service__photo:nth-child(2):before {
    display: none;
  }
}
@media (max-width: 500px) {
  .service__photo:nth-child(2):after,
  .service__photo:nth-child(4):after,
  .service__photo:nth-child(1):before {
    display: none;
  }
  .service__photo:nth-child(1):after,
  .service__photo:nth-child(3):after,
  .service__photo:nth-child(3):before,
  .service__photo:nth-child(2):before {
    display: block;
  }
}
</style>
