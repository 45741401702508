<template>
  <section class="complex">
    <header class="header">
      <div class="complex__circle backend"></div>
      <div class="complex__circle development"></div>
      <h2>Все и сразу!</h2>
      <p>Многие проекты требуют комплексного подхода. В таких случаях намого удобнее когда над проектом работает одна команда, которая понимает поставленную задачу и вместе продумывают план работы.</p>
      <p>У нас есть несколько готовых решений, среди которых вы точно найдете подходящий!</p>

      <div class="header__arrow">
        <svg width="8" height="188" viewBox="0 0 8 188" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.64644 187.354C3.8417 187.549 4.15828 187.549 4.35355 187.354L7.53553 184.172C7.73079 183.976 7.73079 183.66 7.53553 183.464C7.34026 183.269 7.02368 183.269 6.82842 183.464L3.99999 186.293L1.17156 183.464C0.976303 183.269 0.65972 183.269 0.464458 183.464C0.269196 183.66 0.269196 183.976 0.464458 184.172L3.64644 187.354ZM3.5 -2.18557e-08L3.49999 187L4.49999 187L4.5 2.18557e-08L3.5 -2.18557e-08Z" fill="#898989"/>
      </svg>
      </div>
      <div class="complex__circle design"></div>
    </header>
    <Service v-for="(service, index) in services" :key="index" v-bind="service" />
  </section>
</template>

<script>
import Service from '../components/Complex/Service.vue'
export default {
  components: { Service },
  data() {
    return {
      services: [
        {
          name: 'Сайт под ключ',
          chips: [
            { name: 'Разработка', type: 'development'},
            { name: 'Дизайнер', type: 'design'},
            { name: 'Маркетинг', type: 'marketing'}
          ],
          tasks: ['Анализируем Ваш сайт', 'Анализируем нишу', 'Анализируем Лучших конкурентов', 'Составляем маркетинговую стратегию', 'Работаем надо Внутренними факторами', 'Работаем надо внешними факторами', 'Проводим промежуточный анализ', 'Работаем надо коммерческими факторами', 'Работаем над поведенческими факторами', 'Работам над контентом сайта'],
          cost: 70000,
          fullCost: 120000,
          photos: [
            // {
            //   title:'SMM',
            //   image: 'smm',
            //   direction: 'marketing'
            // },
            {
              title:'Контекстная реклама',
              image: 'context',
              direction: 'marketing'
            },
            {
              title:'Таргетированая реклама',
              image: 'target',
              direction: 'marketing'
            },
            {
              title:'Дизайн одностраничника',
              image: 'landing',
              direction: 'design'
            },
            {
              title:'Мобильная версия',
              image: 'mobile',
              direction: 'development'
            }
          ]
        },
        {
          name: 'Долго и дорого',
          chips: [
            { name: 'Разработка', type: 'development'},
            { name: 'Дизайнер', type: 'design'}
          ],
          tasks: ['Анализируем Ваш сайт', 'Анализируем нишу', 'Анализируем Лучших конкурентов', 'Составляем маркетинговую стратегию', 'Работаем надо Внутренними факторами', 'Работаем надо внешними факторами', 'Проводим промежуточный анализ', 'Работаем надо коммерческими факторами', 'Работаем над поведенческими факторами', 'Работам над контентом сайта'],
          cost: 60000,
          fullCost: 100000,
          photos: [
            // {
            //   title:'SMM',
            //   image: 'smm',
            //   direction: 'marketing'
            // },
            {
              title:'Контекстная реклама',
              image: 'context',
              direction: 'marketing'
            },
            {
              title:'Таргетированая реклама',
              image: 'target',
              direction: 'marketing'
            },
            {
              title:'Дизайн одностраничника',
              image: 'landing',
              direction: 'design'
            },
            {
              title:'Мобильная версия',
              image: 'mobile',
              direction: 'development'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.complex {
  width: 81.875em;
  margin: 0 auto;
  box-sizing: border-box;

  overflow-x: hidden;
}
.complex__circle {
  position: absolute;
  opacity: 0.6;
  z-index: -1;
}
.backend {
  width: 20.675em;
  height: 20.675em;
  top: 125px;
  left: 150px;
  background: var(--backend-grad);
}
.development {
  width: 10.675em;
  height: 10.675em;
  top: 125px;
  right: 225px;
  background: var(--development-grad);
}
.design {
  width: 17.675em;
  height: 17.675em;
  top: 440px;
  left: 760px;
  background: var(--design-grad);
}
@media (max-width: 1441px) {
  .backend {
    left: -100px;
  }
  .development{
    right: -10px;
  }
  .design {
    left: 520px;
  }
}
@media (max-width: 1367px) {
  .complex {
    width: 100%;
    padding: 0 65px;
    overflow-x: hidden;
  }
  .backend {
    left: -120px;
  }
  .development{
    right: -35px;
  }
  .design {
    left: 490px;
  }
}
@media (max-width: 1025px) {
  .complex {
    padding: 0 40px;
  }
  .backend {
    left: -150px;
  }
  .development{
    display: none;
  }
}
@media (max-width: 769px) {
  .design {
    top: 385px;
    left: 415px;
  }
}
@media (max-width: 426px) {
  .complex {
    padding: 0 25px;
  }
  .backend {
    top: -50px;
    left: -150px;
  }
  .design {
    background: radial-gradient(50% 50% at 100% 50%, rgba(56, 216, 30, 0.8) 0%, rgba(56, 216, 30, 0) 100%);
    top: 385px;
    left: 142px;
  }
}
@media (max-width: 321px) {
  .complex {
    padding: 0 15px;
  }
  .design {
    top: 385px;
    left: 42px;
  }
}

.header {
  padding: 11.5625em 0 0.8125em;
  overflow-x: hidden;
}
@media (max-width: 865px) {
  .header {
    padding: 11.5625em 0 5.3125em;
  }
}
@media (max-width: 500px) {
  .header {
    padding: 3.125em 0 5.3125em;
  }
}

.header h2 {
  margin-bottom: var(--xl);
}
@media (max-width: 426px) {
  .header h2 {
    font-size: 1.625rem;
  }
}
.header p {
  font-size: 1.125rem;
  line-height: 140%;
  color: var(--light-gray);
  max-width: 30.5em;
  margin-bottom: 1.66em;
}
@media (max-width: 865px) {
  .header p:last-of-type {
    margin-bottom: 0;
  }
}
.header__arrow {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 865px) {
  .header__arrow {
    display: none;
  }
}
</style>
